<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Nieuwe video" overview_route="/videos" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Here you can provide the information needed for the item.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  
                  <div class="col-span-6 sm:col-span-3">
                    <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="video.title">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="google_video_id" class="block text-sm font-medium text-gray-700">Youtube video ID</label>
                    <input type="text" name="google_video_id" id="google_video_id" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="video.google_video_id">
                  </div>

                  <div class="col-span-6 sm:col-span-6 bg-gray-100 p-4 rounded">
                    De Youtube video ID is het ID dat na het = teken in de url wordt gebruikt Bijv: <strong>7NOSDKb0HlU</strong> voor url https://www.youtube.com/watch?v=7NOSDKb0HlU)
                  </div>
                  
                </div>
              </div>
              
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Next
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>

  </main>
</template>

<script>
import VideoService from '../../../services/admin/videos/service';

export default {
  name: 'Video',
  data() {
    return {
      video: {
        id: null,
        title: "",
        google_video_id: ""
      },
      submitted: false
    }
  },
  methods: {
    saveItem() {
      console.log('saving')
      
      VideoService.create(this.video)
        .then(response => {
          this.video.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
          this.$router.push(`/videos/${this.video.id}`);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
}
</script>